import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { SickLeaveReportFilterModel, SickLeaveReportModel } from "../reports/models/sick-leave-reports.model";
import { CareDaysChildrenReportModel, CareDaysReportFilterModel } from "../reports/models/sick-leave-report-children.model";
import { SickLeaveStatisticsRequestModel, SickLeaveStatisticsModel } from "../statistics/models/statistics.model";
import {
  SickLeaveFrontpageStatusModel,
  SickLeaveFollowUpResponseModel,
  SickLeaveFollowUpRequestModel,
  SickLeaveFollowUpItemNoteModel,
  SickLeaveFollowUpItemNoteRequestModel,
  SickLeaveFollowUpModel,
  SickLeaveFollowUpPdfRequestModel,
} from '../follow-up/models/sick-leave-follow-up.model';

@Injectable({ providedIn: 'root' })
export class SickLeaveService {
  constructor(private http: HttpClient) {}

  getSickLeaveReport(request: SickLeaveReportFilterModel | null): Observable<SickLeaveReportModel> {
    return this.http.post<SickLeaveReportModel>(environment.API_URL + '/absence/reports/sickleave', request);
  }

  getCareDaysReport(request: CareDaysReportFilterModel | null): Observable<CareDaysChildrenReportModel> {
    return this.http.post<CareDaysChildrenReportModel>(
      environment.API_URL + '/absence/reports/caredayschildren',
      request
    );
  }

  getCareDaysDetailsReport(request: SickLeaveReportFilterModel | null): Observable<any> {
    return this.http.post<any>(environment.API_URL + '/absence/reports/SickLeaveDetailsPdf', request, {
      responseType: 'blob' as 'json',
    });
  }

  newFollowUpItemNote(followUpId: number, followUpItemId: number, note: SickLeaveFollowUpItemNoteModel ): Observable<SickLeaveFollowUpModel> {
    let request: SickLeaveFollowUpItemNoteRequestModel = {
      Date: note?.Date,
      Note: note?.Note,
      Title: note.Title,
    };

    return this.http.post<SickLeaveFollowUpModel>(
      environment.API_URL + '/sickleave/followup/' + followUpId + '/details/' + followUpItemId + '/addnote', request);
  }

  newFollowUpNote(followUpId: number, note: SickLeaveFollowUpItemNoteModel): Observable<SickLeaveFollowUpModel> {
    let request: SickLeaveFollowUpItemNoteRequestModel = {
      Date: note?.Date,
      Note: note?.Note,
      Title: note.Title,
    };

    return this.http.post<SickLeaveFollowUpModel>(environment.API_URL + '/sickleave/followup/' + followUpId + '/addnote', request);
  }

  editFollowUpItemNote(followUpId: number, followUpItemId: number, note: SickLeaveFollowUpItemNoteModel): Observable<SickLeaveFollowUpModel> {
    let request: SickLeaveFollowUpItemNoteRequestModel = {
      Date: note?.Date,
      Note: note?.Note,
      Title: note.Title,
    };

    return this.http.post<SickLeaveFollowUpModel>(environment.API_URL + '/sickleave/followup/' + followUpId + '/details/' + followUpItemId + '/note/' + note.Id + '/edit', request);
  }

  deleteNote(followUpId: number, followUpItemId: number, noteId: number) {
    return this.http.delete(environment.API_URL + '/sickleave/followup/' + followUpId + '/details/' + followUpItemId + '/note/' + noteId + '/delete');
  }

  getCurrentSickLeaveFollowUpList(): Observable<SickLeaveFollowUpResponseModel> {
    return this.http.get<SickLeaveFollowUpResponseModel>(environment.API_URL + '/sickleave/followup/list/current')
  }

  getPastSickLeaveFollowUpList(filter: SickLeaveFollowUpRequestModel): Observable<SickLeaveFollowUpResponseModel> {
    return this.http.post<SickLeaveFollowUpResponseModel>(
      environment.API_URL + '/sickleave/followup/list/past',
      filter
    );
  }

  getFollowUpDetails(id: number): Observable<SickLeaveFollowUpModel> {
    return this.http.get<SickLeaveFollowUpModel>(environment.API_URL + '/sickleave/followup/' + id + '/details');
  }

  getFrontpageStatus(): Observable<SickLeaveFrontpageStatusModel> {
    return this.http.get<SickLeaveFrontpageStatusModel>(environment.API_URL + '/sickleave/followup/frontpagestatus');
  }

  updateStatus(id: number, itemId: number, completed: boolean): Observable<any> {
    return this.http.post<any>(
      environment.API_URL + '/sickleave/followup/' + id + '/details/' + itemId + '/changestatus',
      { IsCompleted: completed }
    );
  }

  closeFollowUp(id: number): Observable<any> {
    return this.http.post<any>(environment.API_URL + '/sickleave/followup/' + id + '/close', null);
  }

  downloadFollowUpNote(id: number, itemId: number, noteId: number): Observable<any> {
    return this.http.post<any>(
      environment.API_URL + `/sickleave/followup/${id}/details/${itemId}/note/${noteId}/pdf`,
      null,
      { responseType: 'blob' as 'json' }
    );
  }

  getStatistics(filter: SickLeaveStatisticsRequestModel): Observable<SickLeaveStatisticsModel> {
    return this.http.post<SickLeaveStatisticsModel>(environment.API_URL + '/absence/reports/statistics', filter);
  }

  downloadFollowUp(id: number, request: SickLeaveFollowUpPdfRequestModel): Observable<any> {
    return this.http.post<any>(environment.API_URL + `/sickleave/followup/${id}/pdf`, request, {
      responseType: 'blob' as 'json',
    });
  }

  saveFollowUpItemFile(followUpId: number, followUpItemId: number, date: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('date', date);
    return this.http.post<File>(environment.API_URL + '/sickleave/followup/' + followUpId + '/details/' + followUpItemId + '/addfile', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  saveFollowUpFile(followUpId: number, date: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('date', date);
    return this.http.post<File>(environment.API_URL + '/sickleave/followup/' + followUpId + '/addfile', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  deleteFollowUpFile(followUpId: number, followUpItemId: number, fileId: number): Observable<SickLeaveFollowUpModel> {
    return this.http.delete<SickLeaveFollowUpModel>(environment.API_URL + `/sickleave/followup/${followUpId}/details/${followUpItemId}/file/${fileId}/delete`);
  }

  downloadSickLeaveFollowUpFile(followUpId: number, followUpItemId: number, fileId: number): Observable<any> {
    return this.http.post<any>(environment.API_URL + `/sickleave/followup/${followUpId}/details/${followUpItemId}/file/${fileId}/download`,
      {},
      { responseType: 'blob' as 'json' }
    );
  }

  deleteFollowUp(id: number) {
    return this.http.delete(environment.API_URL + '/sickleave/followup/' + id + '/delete');
  }

}
