import { createReducer, on } from "@ngrx/store";
import { AggregationTypeEnum, DepartmentPeriodOptionsEnum, SickLeaveStatisticsFilterModel, SickLeaveStatisticsModel, SickLeaveStatisticsTypeEnum } from "../models/statistics.model";
import { SickLeaveStatisticsApiActions, SickLeaveStatisticsPageActions } from "./statistics.actions";

export const featureKey = 'statistics';

export interface State {
  loaded: boolean;
  error: boolean;
  errorMessage?: string;
  errorCode: string;
  filter: SickLeaveStatisticsFilterModel;
  statistics: SickLeaveStatisticsModel;
}

export const initialState: State = {
  loaded: false,
  error: false,
  errorCode: '',
  errorMessage: '',
  filter: {
    StatisticsType: SickLeaveStatisticsTypeEnum.Organization,
    Years: [new Date().getFullYear()],
    AggregationType: AggregationTypeEnum.ByYear,
    DepartmentIds: [],
    EmployeeIds: [],
    AbsenceReportLengths: [],
    DepartmentPeriodOptions: DepartmentPeriodOptionsEnum.Merged
  },
  statistics: {}
};

export const reducer = createReducer<State>(
  initialState,
  on(SickLeaveStatisticsPageActions.SetFilter, (state, { filter }): State => {
    return {
      ...state,
      filter: filter,
      loaded: false
    };
  }),
  on(SickLeaveStatisticsApiActions.LoadStatisticsSuccess, (state, { response }): State => {
    return {
      ...state,
      loaded: true,
      error: false,
      statistics: response
    };
  }),
  on(SickLeaveStatisticsApiActions.LoadStatisticsError, (state, { code, message }): State => {
    return {
      ...state,
      loaded: true,
      error: true,
      errorCode: code,
      errorMessage: message
    };
  }),
  on(SickLeaveStatisticsPageActions.ClearData, (state): State => {
    return {
      ...state,
      statistics: {}
    };
  }),
);
