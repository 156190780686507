import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-footer',
  template: ` <div class="main-footer">
    <div class="main-footer__info">
      <a href="#"
        ><img src="/assets/images/logo-color.svg" class="main-footer__logo" alt="{{ 'app-name' | translate | async }}"
      /></a>
      <span>{{ 'service-delivered-by' | translate | async }} <a href="https://www.mentorpluss.no/">PBL Mentor AS</a></span>
    </div>

    <ul class="main-footer__links">
      <li>
        <a href="https://www.mentorpluss.no/bruksvilkar" target="_blank">{{ 'terms' | translate | async }}</a>
      </li>
      <li>
        <a href="https://www.mentorpluss.no/personvernerklaering" target="_blank">{{
          'privacy' | translate | async
        }}</a>
      </li>
      <!-- <li>{{ 'version' | translate | async }}: '[[BuildNumber]]'</li> -->
    </ul>
  </div>`,
  styleUrls: ['./main-footer.component.scss'],
})
export class MainFooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
