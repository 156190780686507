import { createSelector } from '@ngrx/store';
import { selectEmployeesState } from '..';
import { selectAllemployees } from './employee.selectors';
import * as _ from 'underscore';
import { selectManageableEmployeeIds } from '@mentor-one-ui/core/state/user/user.selector';
import { selectUserRole } from '@mentor-one-ui/core/state/access/access.selector';
import { EmployeesColumn } from '@mentor-one-ui/employees/employee-list-page/models/employees-column.enum';
import { selectAllDepartments, selectDepartmentIds } from '@mentor-one-ui/user-organization/components/departments/state/departments.selectors';
import { SelectItemGroup } from 'primeng/api';

const selectFilter = createSelector(selectEmployeesState, (state) => state?.employees.filter);

const selectNonArchivedEmployees = createSelector(selectAllemployees, (employees) =>
  employees.filter((employee) => !employee.IsArchived)
);

// filter.departments
const selectDepartmentFilteredEmployees = createSelector(
  selectNonArchivedEmployees,
  selectFilter,
  (activeEmployees, filters) => {
    if (!filters.departments || !filters.departments.some((x) => Number.isFinite(x))) {
      return activeEmployees;
    }
    let departmentFilteredEmployees = activeEmployees.filter((employee) =>
      employee.ContactInformation.DepartmentIds.some((departmentId) => filters.departments.includes(departmentId))
    );
    return departmentFilteredEmployees;
  }
);
// filter.isActive
const selectIsActiveFilteredEmployees = createSelector(
  selectDepartmentFilteredEmployees,
  selectFilter,
  (activeEmployees, filters) => {
    if (!filters.isActive) {
      return activeEmployees;
    }
    let isActiveFilteredEmployees = activeEmployees.filter((employee) => employee.ContactInformation.IsActive);
    return isActiveFilteredEmployees;
  }
);
// filter.search
const selectFilteredEmployees = createSelector(
  selectIsActiveFilteredEmployees,
  selectManageableEmployeeIds,
  selectFilter,
  (activeEmployees, managebleIds, filters) => {
    if (!filters.search || filters.search === '') {
      return activeEmployees.map((employee) => {
        return { ...employee, CanEdit: managebleIds.includes(employee.EmployeeId) };
      });
    }
    let searchFilteredEmployees = activeEmployees.filter((employee) =>
      (
        employee.ContactInformation.FirstName.toLowerCase() +
        ' ' +
        employee.ContactInformation.LastName.toLowerCase()
      ).includes(filters.search.toLowerCase())
    );

    return searchFilteredEmployees
      .sort((a, b) => {
        if (a.ContactInformation.FirstName > b.ContactInformation.FirstName) {
          return 1;
        }

        if (a.ContactInformation.FirstName < b.ContactInformation.FirstName) {
          return -1;
        }

        return 0;
      })
      .map((employee) => {
        return { ...employee, CanEdit: managebleIds.includes(employee.EmployeeId) };
      });
  }
);

const selectArchivedEmployees = createSelector(selectAllemployees, (employees) => {
  let filteredEmployees = employees.filter((employee) => employee.IsArchived);
  return filteredEmployees;
});

const selectSelectedEmployeeId = createSelector(selectEmployeesState, (state) => {
  return state?.employees.selectedEmployeeId;
});

const selectSelectedEmployee = createSelector(
  selectAllemployees,
  selectSelectedEmployeeId,
  (employees, selectedEmployeeId) => employees.find((employee) => employee.EmployeeId === selectedEmployeeId)
);

const initialNumberOfEmployees = createSelector(selectNonArchivedEmployees, (employees) => employees.length);

export const selectAvailableColumns = createSelector(selectFilter, selectUserRole, (filter, role) => {
  let columns = filter?.columns.slice(0, 3);
  if (role.isMentorPersonalAdministrator || role.isPersonnelManager) {
    columns.push(EmployeesColumn.LoggedIn);
    columns.push(EmployeesColumn.Profile);
  }
  if (role.isMentorPersonalAdministrator) {
    columns.push(EmployeesColumn.Roles);
  }
  return columns;
});
export const selectSelectedColumns = createSelector(selectFilter, (filter) => filter?.selectedColumns);
export const selectHasArchivedEmployees = createSelector(selectArchivedEmployees, (employees) => employees.length > 0);

export const EmployeeListSelectors = {
  selectFilter,
  selectFilteredEmployees,
  selectArchivedEmployees,
  initialNumberOfEmployees,
  selectFilterIsActive: createSelector(selectFilter, (filter) => filter?.isActive),
  selectSelectedEmployee,
  selectEmployeesLoadError: createSelector(selectEmployeesState, (state) => state?.employees.loadError),
  selectEmployeesLoadErrorCode: createSelector(selectEmployeesState, (state) => state?.employees.loadErrorCode),
  selectEmployeesLoadErrorMessage: createSelector(selectEmployeesState, (state) => state?.employees.loadErrorMessage),
  selectEmployeesLoading: createSelector(selectEmployeesState, (state) => state?.employees.isLoading),
  selectNonArchivedEmployees
};
