import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { UserSelectors } from '../user/user.selector';
import { ApplicationActions } from './application.actions';
import { MessageService } from 'primeng/api';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { selectRouteData, selectUrl } from '../router/router.selectors';
import { ThemeService } from '@mentor-one-ui/core/services/theme.service';
import { selectIsDarkMode } from './application.selectors';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { SelectOrganizationModalComponent } from '@mentor-one-ui/core/components/select-organization-modal/select-organization-modal.component';
import { OrganizationDetailsApiActions } from '@mentor-one-ui/mentor-admin/admin-organizations/state/actions/organization-details-api.actions';
import { OrganizationService } from '@mentor-one-ui/core/services/api/organization.service';

@Injectable()
export class ApplicationEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private messageService: MessageService,
    private themeService: ThemeService,
    private dialogService: DialogService,
    private translationService: TranslationDataService,
    private organizationService: OrganizationService
  ) {}

  setActiveTheme$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ApplicationActions.SetActiveThemeMode),
        concatLatestFrom(() => [this.store.select(selectIsDarkMode)]),
        map(([, isdarkmode]) => {
          if (isdarkmode) {
            this.themeService.switchTheme('pbl-dark');
          } else {
            this.themeService.switchTheme('pbl-light');
          }
        })
      );
    },
    { dispatch: false }
  );

  showSuccessToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ApplicationActions.SuccessMessage),
        map((action) => {
          this.messageService.add({ severity: 'success', summary: action.title, detail: action.message });
        })
      );
    },
    { dispatch: false }
  );

  showInfoToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ApplicationActions.InfoMessage),
        map((action) => {
          this.messageService.add({ severity: 'info', summary: action.title, detail: action.message });
        })
      );
    },
    { dispatch: false }
  );

  showErrorToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ApplicationActions.ErrorMessage),
        map((action) => {
          this.messageService.add({
            key: 'error',
            severity: 'error',
            summary: action.title,
            detail: action.message,
            life: 5000,
          });
        })
      );
    },
    { dispatch: false }
  );
  setActiveMenuModule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      concatLatestFrom(() => [this.store.select(selectUrl), this.store.select(selectRouteData)]),
      map(([, , routeData]) => {
        let activeMenuModule = routeData?.activeMenuModule;
        if (activeMenuModule) {
          return ApplicationActions.SetActiveMenuModule({ activeMenuModule: activeMenuModule });
        }
        return ApplicationActions.SetActiveMenuModule({ activeMenuModule: '' });
      })
    );
  });

  dialogRef: DynamicDialogRef;

  openRoleOverviewModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ApplicationActions.OpenOrganizationSelectModal),
        concatLatestFrom(() => [this.store.select(UserSelectors.selectAvailabeOrganziations)]),
        switchMap(([, organizations]) => {
          this.dialogRef = this.dialogService.open(SelectOrganizationModalComponent, {
            header: this.translationService.translate('change-organization'),
            width: '35rem',
            styleClass: 'select-organization-modal',
            closable: true,
            data: {
              organizations: organizations,
            },
          });

          return this.dialogRef.onClose;
        })
      ),
    { dispatch: false }
  );

  dialogClosed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ApplicationActions.CloseOrganizationSelectModal),
        map(() => {
          if (this.dialogRef) {
            this.dialogRef.close();
          }
        })
      ),
    { dispatch: false }
  );

  sendSupportLogoutRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationDetailsApiActions.SupportLogoutRequest),
      mergeMap((action) => {
        return this.organizationService.supportLogoutRequest(action.sid).pipe(
          map((result) => {
            return OrganizationDetailsApiActions.SupportLogoutRequestSuccess();
          }),
          catchError((err) => of(OrganizationDetailsApiActions.SupportLogoutRequestError(err)))
        );
      })
    );
  });

  resetScrollToTop$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ApplicationActions.ResetScroll),
        map(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          document.getElementsByTagName('main')[0].focus();
        })
      ),
    { dispatch: false }
  );
}
