import { AbsenceModel } from '@mentor-one-ui/time/my-time/absence/models/absence.model';
import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';
import { createAction, props } from '@ngrx/store';

export const ManageEditAbsencePageActions = {
  Enter: createAction('[ManageEditAbsencePage] Enter'),
  HasFormChanges: createAction('[ManageEditAbsencePage] Has form changes', props<{ hasChanges: boolean }>()),
  EditAbsence: createAction('[ManageEditAbsencePage] Edit absence', props<{ absence: AbsenceModel }>()),
  DeleteAbsence: createAction(
    '[ManageEditAbsencePage] Delete Absence',
    props<{ leaveType: TimeManagementType; id: number; isExported: boolean | null }>()
  ),
  DeleteAbsenceConfirmed: createAction('[ManageEditAbsencePage] Delete Absence confirmed'),
  DeleteAbsenceCanceled: createAction('[ManageEditAbsencePage] Delete Absence canceled'),
};
