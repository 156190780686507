import { EmployeeDropdownModel } from "./employee/EmployeeModel";

export class DepartmentLightModel {
  DepartmentId: number;
  Name: string;
}

export interface DepartmentModel {
  DepartmentId: number;
  Name: string;
  IsActive: boolean;
  Description: string;
  EmployeeIds: number[];
}

export function createEmptyDepartmentModel(): DepartmentModel {
  return {
    DepartmentId: -1,
    Name: '',
    IsActive: true,
    EmployeeIds: [],
    Description: '',
  };
}

export function createDeparmentModelWithName(name: string): DepartmentModel {
  return {
    DepartmentId: -1,
    Name: name,
    IsActive: true,
    EmployeeIds: [],
    Description: '',
  };
}

