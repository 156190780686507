import { createSelector } from '@ngrx/store';
import { selectLeaveManagementState } from '.';

export const selectLeaveApprovalState = createSelector(selectLeaveManagementState, (state) => state.leaveApproval);

export const selectApprovalPageState = createSelector(selectLeaveApprovalState, (state) => state.approvalPage);

export const selectIsSaving = createSelector(selectApprovalPageState, (state) => state.isSaving);

export const selectSelectedId = createSelector(selectApprovalPageState, (state) => state.selectedId);

export const selectSelectedAbsence = createSelector(selectApprovalPageState, (state) => state.selectedAbsence);

export const selectBalance = createSelector(selectApprovalPageState, (state) => state.balance);

export const selectValidationResult = createSelector(selectApprovalPageState, (state) => state.validationResult);

export const selectUserPersonnelManagers = createSelector(selectApprovalPageState, (state) => state.personnelManagers);

export const selectSelectedOvertime = createSelector(selectApprovalPageState, (state) => state.selectedOverTime);

export const selectAbsencePageViewModel = createSelector(
  selectSelectedAbsence,
  selectBalance,
  selectValidationResult,
  selectUserPersonnelManagers,
  selectIsSaving,
  (absence, balance, validationResult, personnelManagers, isSaving) => {
    return {
      absence,
      balance,
      validationResult,
      personnelManagers,
      isSaving,
      loaded: absence !== null && balance !== null && validationResult !== null,
    };
  }
);

export const selectOvertimePageViewModel = createSelector(
  selectSelectedOvertime,
  selectBalance,
  selectValidationResult,
  (overtime, balance, validationResult) => {
    return {
      overtime,
      balance,
      validationResult,
    };
  }
);
