import { createReducer, on } from '@ngrx/store';
import { ManageApiActions } from '../actions/manage-api.actions';
import {
  LeaveManagementPageActions,
  LeaveManagementDetailsPageActions,
} from '../../containers/leave-management-page/actions/manage-absence.actions';
import { UnmanagedLeavesPageActions } from '../../containers/unmanaged-leaves-page/actions/unmanaged-leaves-page.actions';
import { AbsenceModel } from '@mentor-one-ui/time/my-time/absence/models/absence.model';
import { BalanceModel } from '@mentor-one-ui/time/my-time/balance/models/balance.model';
import { ManageViewAbsencePageActions } from '../../containers/manage-view-absence-page/actions/manage-view-absence-page.actions';
import { AbsenceValidationResultModel } from '@mentor-one-ui/time/my-time/absence/models/absence-validation-result.model';
import { PersonnelManagerModel } from '@mentor-one-ui/core/models/employee/EmploymentModel';
import { OvertimeModel } from '@mentor-one-ui/time/my-time/overtime/models/overtime.model';
import { ManageViewOvertimePageActions } from '../../containers/manage-view-overtime-page/actions/manage-view-overtime-page.actions';

export const featureKey = 'approvalPage';
export interface State {
  isSaving: boolean;
  selectedId: number;
  selectedAbsence: AbsenceModel | null;
  selectedOverTime: OvertimeModel | null;
  balance: BalanceModel[] | null;
  validationResult: AbsenceValidationResultModel | null;
  personnelManagers: PersonnelManagerModel[] | null;
}

export const initialState: State = {
  isSaving: false,
  selectedId: -1,
  selectedAbsence: null,
  selectedOverTime: null,
  balance: null,
  validationResult: null,
  personnelManagers: null,
};

export const reducer = createReducer(
  initialState,
  on(ManageViewAbsencePageActions.Enter, ManageViewOvertimePageActions.Enter, (state): State => {
    return initialState;
  }),
  on(
    UnmanagedLeavesPageActions.ApproveLeaveRequest,
    LeaveManagementDetailsPageActions.ApproveLeaveRequest,
    (state, action): State => {
      return {
        ...state,
        isSaving: true,
        selectedId: action.leaveId,
      };
    }
  ),
  on(ManageApiActions.ApproveAbsenceSuccess, (state, { id }): State => {
    return {
      ...state,
      isSaving: false,
      selectedId: -1,
    };
  }),

  on(LeaveManagementPageActions.RejectLeaveRequest, (state): State => {
    return {
      ...state,
      isSaving: true,
    };
  }),

  on(ManageApiActions.RejectAbsenceSuccess, (state, { id }): State => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(ManageApiActions.RejectAbsenceError, ManageApiActions.ApproveAbsenceError, (state): State => {
    return {
      ...state,
      isSaving: false,
      selectedId: -1,
    };
  }),

  on(ManageApiActions.LoadAbsenceDetailSuccess, (state, { absence }): State => {
    return {
      ...state,
      selectedAbsence: absence,
    };
  }),
  on(ManageApiActions.loadEmployeeBalanceSuccess, (state, { balance }): State => {
    return {
      ...state,
      balance,
    };
  }),
  on(ManageApiActions.loadAbsenceValidationResultSuccess, (state, { validationResult }): State => {
    return {
      ...state,
      validationResult,
    };
  }),
  on(ManageApiActions.loadEmployeePersonalManagersSuccess, (state, { personnelManagers }): State => {
    return {
      ...state,
      personnelManagers,
    };
  }),
  on(ManageApiActions.LoadOvertimeDetailSuccess, (state, { overtime }): State => {
    return {
      ...state,
      selectedOverTime: overtime,
    };
  }),
  on(ManageApiActions.loadConflictingRegistrationsSuccess, (state, { conflictingRegistrations }): State => {
    return {
      ...state,
      validationResult: { ConflictingRegistrations: conflictingRegistrations } as AbsenceValidationResultModel,
    };
  })
);
