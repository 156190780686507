import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ButtonBarButton, ButtonIconPosition } from '@mentor-one-ui/core/models/ButtonBar';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'mo-button-bar',
  styleUrls: ['./button-bar.scss'],
  template: `
    <div class="button-bar">
      <span *ngFor="let button of buttonList">
        <span *ngIf="!button.routerLink && button.visible" [pTooltip]="button.tooltip" tooltipPosition="bottom">
          <button
            [id]="button.id"
            pButton
            type="button"
            (click)="handleButtonClick(button)"
            (routerLink)="(button.routerLink)"
            icon="{{ button.icon }}"
            [iconPos]="button.iconPos || iconPos"
            [class]="button.class"
            [loading]="(button?.isSaveButton && isSaving)!"
            [pTooltip]="saveToolTip"
            [disabled]="
              disableButton(button.disabled) || isSaving || (button?.isSaveButton && !canSave) || disableButtons
            "
            [label]="button.label"
          ></button>
        </span>
        <a pButton *ngIf="button.visible && button.routerLink" [routerLink]="button.routerLink">{{ button.label }}</a>
      </span>
    </div>
  `,
})
export class ButtonBarComponent implements OnInit, OnChanges {
  @Input() buttonList: ButtonBarButton[];
  @Input() isSaving: boolean;
  @Input() disableButtons: boolean;
  @Input() canSave: boolean = true;
  @Input() saveToolTip: string;
  @Output() buttonBarClick: EventEmitter<ButtonBarButton> = new EventEmitter<ButtonBarButton>();
  iconPos: 'left' | 'right' = 'left';

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.buttonList) {      
      this.buttonList = this.buttonList || [];
    }
  }

  ngOnInit(): void {}

  handleButtonClick(button: ButtonBarButton) {
    this.buttonBarClick.emit(button);
  }

  disableButton(value: Boolean) {
    return value ? 'disabled' : '';
  }
}
