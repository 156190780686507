export interface SickLeaveStatisticsRequestModel {
  Years: number[];
  StatisticsType: SickLeaveStatisticsTypeEnum;
  AggregationType: AggregationTypeEnum;
  DepartmentIds: number[] | null;
  EmployeeIds: number[] | null;
  AbsenceReportLengths: SickLeaveReportLengthEnum[] | null;
}

export enum SickLeaveStatisticsTypeEnum {
  Organization = 0,
  Department = 1,
  Employee = 2
}

export enum AggregationTypeEnum {
  ByYear = 0,
  ByQuarter = 1,
  ByMonth = 2
}

export enum DepartmentPeriodOptionsEnum {
  Merged = 0,
  Compared = 1,
}

export enum SickLeaveReportLengthEnum {
  TotalAllLengths = 0,
  SelfCertified = 10,
  ShortMedicalCertificate = 20,
  MediumMedicalCertificate = 30,
  LongerMedicalCertificate = 40,
  ProlongedMedicalCertificate = 50
}

export interface SickLeaveStatisticsFilterModel {
  Years: number[];
  StatisticsType: SickLeaveStatisticsTypeEnum;
  AggregationType: AggregationTypeEnum;
  DepartmentIds: number[];
  EmployeeIds: number[];
  AbsenceReportLengths: SickLeaveReportLengthEnum[];
  DepartmentPeriodOptions: DepartmentPeriodOptionsEnum;
}

export interface SickLeaveStatisticsModel {
  ResultStatisticsType?: SickLeaveStatisticsTypeEnum;
  AggregationType?: AggregationTypeEnum;
  Years?: SickLeaveStatisticsYearModel[];
}

export interface SickLeaveStatisticsYearModel {
  Year: number;
  Aggregations: SickLeaveStatisticsAggregationModel[];
  TotalManDays: number;
  TotalAbsenceDays: number;
  TotalOccurrences: number;
  TotalAbsencePercentage: number;
  TotalAggregations: SickLeaveStatisticsTimePeriodModel[];
}

export interface SickLeaveStatisticsAggregationModel {
  Title: string;
  Id: number | null;
  TimePeriods: SickLeaveStatisticsTimePeriodModel[];
  TotalManDays: number;
  TotalAbsenceDays: number;
  TotalOccurrences: number;
  TotalAbsencePercentage: number;
}

export interface SickLeaveStatisticsTimePeriodModel {
  Title: string;
  TimePeriodAggregation: SickLeaveStatisticsTimePeriodAggregationModel[];
  TotalManDays: number;
  TotalAbsenceDays: number;
  TotalOccurrences: number;
  TotalAbsencePercentage: number;
  TotalWorkingDays: number;
  TotalPregnancyRelatedLeavePercentage: number;
  TotalChronicDiseaseDecisionPercentage: number;
  TotalWorkRelatedSicknessPercentage: number;
}

export interface SickLeaveStatisticsTimePeriodAggregationModel {
  Title: string;
  AbsenceDays: number;
  AbsenceOccurrences: number;
  AbsencePercentage: number;
  PregnancyRelatedLeaveDays: number;
  ChronicDiseaseDecisionDays: number;
  WorkRelatedSicknessDays: number;
  SickLeaveReportLengthEnum: SickLeaveReportLengthEnum;
  Index: number;
  DepartmentId: number;
  DepartmentTitle: string;
  TotalAbsenceDays: number;
  TotalAbsencePercentage: number;
}

export class SickLeaveStatisticsViewModel {
  GroupTitle?: string;
  TotalManDays?: number;
  TotalAbsenceDays?: number;
  TotalOccurrences?: number;
  TotalAbsencePercentage?: number;
  TotalPregnancyRelatedLeavePercentage?: number;
  TotalChronicDiseaseDecisionPercentage?: number;
  TotalWorkRelatedSicknessPercentage?: number;
  TotalWorkingDays?: number;
  Details?: SickLeaveStatisticsDetailsViewModel[];
  Columns?: ColumnsDetailsViewModel[];
}

export class SickLeaveStatisticsDetailsViewModel {
  SortOrder?: number;
  ManDays?: number;
  AbsenceDays?: number;
  AbsenceOccurrences?: number;
  AbsencePercentage?: number;
  Title: string;
  Departments?: SickLeaveStatisticsDetailsViewModel[];
}

export class ColumnsDetailsViewModel {
  Id: number;
  Title: string;
  TotalManDays?: number;
  TotalWorkingDays?: number;
}

export class SickLeaveStatisticsDiagramViewModel {
  GraphLabels: string[];
  Labels: any[];
  Data: any[];
  Total: any[];
}

