import { PersonnelManagerModel } from '@mentor-one-ui/core/models/employee/EmploymentModel';
import { AbsenceValidationResultModel } from '@mentor-one-ui/time/my-time/absence/models/absence-validation-result.model';
import { AbsenceModel } from '@mentor-one-ui/time/my-time/absence/models/absence.model';
import { TimeManagementModel } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementModel';
import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';
import { BalanceModel } from '@mentor-one-ui/time/my-time/balance/models/balance.model';
import { OvertimeModel } from '@mentor-one-ui/time/my-time/overtime/models/overtime.model';
import { createAction, props } from '@ngrx/store';

export const ManageApiActions = {
  loadUnmanagedAbsencesSuccess: createAction(
    '[Manage API] Load Unmanaged Absences Success',
    props<{ unmanagedAbsences: TimeManagementModel[]; totalAbsences: number }>()
  ),
  loadUnmanagedAbsencesError: createAction('[Manage API] Load Unmanaged Absences Error', props<{ error: string }>()),
  loadManagedAbsencesSuccess: createAction(
    '[Manage API] Load Managed Absences Success',
    props<{ managedAbsences: TimeManagementModel[]; totalAbsences: number }>()
  ),
  loadManagedAbsencesError: createAction('[Manage API] Load Managed Absences Error', props<{ error: string }>()),
  ApproveAbsenceSuccess: createAction('[Manage API] Approve Absence Success', props<{ id: number }>()),
  ApproveAbsenceError: createAction('[Manage API] Approve Absence Error', props<{ error: string }>()),
  RejectAbsenceSuccess: createAction(
    '[Manage API] Reject Absence Success',
    props<{ id: number; leaveType: TimeManagementType }>()
  ),
  RejectAbsenceError: createAction('[Manage API] Reject Absence Error', props<{ error: string }>()),
  RegisterBulkLeaveSuccess: createAction('[Manage API] Register Bulk Leave Success'),
  RegisterBulkLeaveError: createAction('[Manage API] Register Bulk Leave Error', props<{ error: string }>()),
  EditAbsenceSuccess: createAction('[Manage API] Edit Absence Success'),
  EditAbsenceError: createAction('[Manage API] Edit Absence Error', props<{ error: string }>()),

  DeleteLeaveSuccess: createAction('[Manage API] Delete Leave Success'),
  DeleteLeaveError: createAction('[Manage API] Delete Leave Error', props<{ error: string }>()),

  EditOvertimeSuccess: createAction('[Manage API] Edit Overtime Success'),
  EditOvertimeError: createAction('[Manage API] Edit Overtime Error', props<{ error: string }>()),

  LoadAbsenceDetailSuccess: createAction(
    '[Manage API] Load Absence Detail Success',
    props<{ absence: AbsenceModel }>()
  ),
  LoadAbsenceDetailError: createAction('[Manage API] Load Absence Detail Error', props<{ message: string }>()),

  loadEmployeeBalanceSuccess: createAction(
    '[Manage API] Load Employee Balance Success',
    props<{ balance: BalanceModel[] }>()
  ),
  loadEmployeeBalanceError: createAction('[Manage API] Load Employee Balance Error', props<{ message: string }>()),
  loadAbsenceValidationResultSuccess: createAction(
    '[Manage API] Load Absence Validation Result Success',
    props<{ validationResult: AbsenceValidationResultModel }>()
  ),
  loadAbsenceValidationResultError: createAction(
    '[Manage API] Load Absence Validation Result Error',
    props<{ message: string }>()
  ),
  loadEmployeePersonalManagersSuccess: createAction(
    '[Manage API] Load Employee Personal Managers Success',
    props<{ personnelManagers: PersonnelManagerModel[] }>()
  ),
  loadEmployeePersonalManagersError: createAction(
    '[Manage API] Load Employee Personal Managers Error',
    props<{ message: string }>()
  ),
  LoadOvertimeDetailSuccess: createAction(
    '[Manage API] Load Overtime Detail Success',
    props<{ overtime: OvertimeModel }>()
  ),
  LoadOvertimeDetailError: createAction('[Manage API] Load Overtime Detail Error', props<{ message: string }>()),
  loadConflictingRegistrationsSuccess: createAction(
    '[Manage API] Load Conflicting Registrations Success',
    props<{ conflictingRegistrations: TimeManagementModel[] }>()
  ),
  loadConflictingRegistrationsError: createAction(
    '[Manage API] Load Conflicting Registrations Error',
    props<{ message: string }>()
  ),
};
