import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  viewChild,
} from '@angular/core';
import { ButtonBarButton, ButtonAction } from '@mentor-one-ui/core/models/ButtonBar';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, tap } from 'rxjs';
import { TimeManagementModel } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementModel';
import { selectIsSaving } from '../../state/selectors/leave-approval.selectors';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';

@Component({
  selector: 'app-reject-leave-dialog',
  styleUrls: ['./reject-leave-dialog.component.scss'],
  template: `
    <div class="pbl-input-text">
      <h1>{{ 'reject-registration' | translate | async }}</h1>
      <label for="declineComment">{{ 'comment' | translate | async }}</label>
      <textarea
        #declineComment
        [(ngModel)]="comment"
        pInputTextarea
        placeholder="{{ 'reason-for-rejection' | translate | async }}"
        itemid="declineComment"
        maxlength="1000"
        (keydown)="saveOnEnter($event)"
      ></textarea>
    </div>
    <mo-button-bar
      [canSave]="comment.length > 0"
      [isSaving]="(isSaving$ | async)!"
      [buttonList]="declineButtons"
      (buttonBarClick)="handleButtonBarClick($event)"
    >
    </mo-button-bar>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RejectLeaveDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('declineComment') declineComment: ElementRef;
  declineButtons: ButtonBarButton[];
  absence: TimeManagementModel;
  comment: string = '';
  isSaving$: Observable<boolean | null> = this.store.select(selectIsSaving);

  constructor(
    private store: Store,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private translationService: TranslationDataService
  ) {
    this.declineButtons = [
      {
        label: this.translationService.translate('reject'),
        routerLink: null,
        action: ButtonAction.Save,
        visible: true,
        disabled: false,
        isSaveButton: true,
        icon: 'fal fa-check',
        class: 'p-button p-button-primary',
      } as ButtonBarButton,
      {
        label: this.translationService.translate('cancel'),
        routerLink: null,
        action: ButtonAction.Cancel,
        visible: true,
        disabled: false,
        icon: 'fal fa-times',
        class: 'p-button p-button-secondary',
      } as ButtonBarButton,
    ];
  }
  ngAfterViewInit(): void {
    this.declineComment.nativeElement.focus();
  }

  ngOnInit(): void {
    this.absence = this.config?.data?.model;
  }

  saveOnEnter(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'Enter') {
      this.ref?.close({ save: true, leaveId: this.absence.Id, leaveTypeId: this.absence.Type, comment: this.comment });
    }
  }

  handleButtonBarClick(button: ButtonBarButton) {
    if (button.action === ButtonAction.Cancel) {
      this.ref?.close(null);
    }
    if (button.action === ButtonAction.Save) {
      this.ref?.close({ save: true, leaveId: this.absence.Id, leaveTypeId: this.absence.Type, comment: this.comment });
    }
  }
}
