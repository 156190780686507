import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';
import { OvertimeModel } from './models/overtime.model';
import { OvertimeType } from './models/overtime-type.model';
import { environment } from 'src/environments/environment';
import { TimeValidatorService } from '@mentor-one-ui/time/shared/time-validator.service';
import { OvertimeEmployeeListResponseModel } from './models/overtime-employee-list-response.model';
import { TimeManagementModel } from '../absence/models/TimeManagementModel';

@Injectable({
  providedIn: 'root',
})
export class OvertimeService {
  constructor(public http: HttpClient, private timeValidatorService: TimeValidatorService) {}

  getOvertimeTypes(): Observable<OvertimeType[]> {
    return this.http.get<OvertimeType[]>(environment.API_URL + '/Overtime/template/organization/list');
  }

  getOvertimes(
    employeeId: number,
    typeFilter: number[],
    statusFilter: number,
    skip: number = 0,
    take: number = 10
  ): Observable<OvertimeEmployeeListResponseModel> {
    const body = {
      OvertimeMapIds: typeFilter,
      Skip: skip,
      Take: take,
      Status: statusFilter != null && statusFilter > -1 ? statusFilter : null,
    };

    return this.http
      .post<OvertimeEmployeeListResponseModel>(environment.API_URL + '/Overtime/' + employeeId + '/list', body)
      .pipe(
        map((response) => {
          response.Items.map((overtime) => {
            overtime.FromTime = this.timeValidatorService.removeSecondsFromTimeString(overtime.FromTime);
            overtime.ToTime = this.timeValidatorService.removeSecondsFromTimeString(overtime.ToTime);
            return overtime;
          });

          return response;
        })
      );
  }

  registerOvertime(employeeId: number, overtime: OvertimeModel): Observable<OvertimeModel> {
    return this.http.post<OvertimeModel>(environment.API_URL + '/Overtime/' + employeeId + '/add', overtime);
  }

  registerOvertimeCollection(employeeId: number, overtimeCollection: OvertimeModel[]): Observable<OvertimeModel[]> {
    return this.http.post<OvertimeModel[]>(
      environment.API_URL + '/Overtime/' + employeeId + '/addcollection',
      overtimeCollection
    );
  }

  updateOvertime(overtime: OvertimeModel): Observable<OvertimeModel> {
    return this.http.post<OvertimeModel>(
      environment.API_URL + '/Overtime/' + overtime.OvertimeId + '/update',
      overtime
    );
  }

  deleteOvertime(overtimeId: number) {
    return this.http.delete<any>(environment.API_URL + '/Overtime/' + overtimeId + '/delete');
  }

  getOvertime(id: number): Observable<OvertimeModel> {
    return this.http.get<OvertimeModel>(environment.API_URL + '/Overtime/' + id).pipe(
      map((overtime) => {
        overtime.FromTime = this.timeValidatorService.removeSecondsFromTimeString(overtime.FromTime);
        overtime.ToTime = this.timeValidatorService.removeSecondsFromTimeString(overtime.ToTime);
        return overtime;
      })
    );
  }

  getConflictingRegistrations(employeeId: number, date: string): Observable<TimeManagementModel[]> {
    let params = 'EmployeeId=' + employeeId + '&Date=' + date;
    return this.http.get<TimeManagementModel[]>(environment.API_URL + '/Overtime/conflictingRegistrations?' + params);
  }
}
