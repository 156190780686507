import { createSelector, select } from '@ngrx/store';
import { adapter } from '../reducers/employees.reducer';
import { selectRoutedEmployeeId } from '@mentor-one-ui/core/state/router/router.selectors';
import { EmployeeDropdownModel, EmployeeModel } from '@mentor-one-ui/core/models/employee/EmployeeModel';
import { selectEmployeesState } from '@mentor-one-ui/employees/state';
import {
  UserSelectors,
  selectHasPermission,
  selectManageableEmployeeIds,
  selectManageableEmployeesIsLoaded,
} from '@mentor-one-ui/core/state/user/user.selector';
import { departmentSelectors } from '@mentor-one-ui/user-organization/components/departments/state/departments.selectors';
import { AppPermissionEnum } from '@mentor-one-ui/core/permissions/app-permissions';
import { SystemEnum } from '@mentor-one-ui/core/models/enums';
import { UserRole } from '@mentor-one-ui/core/state/user/user.model';

export const selectEmployeeState = createSelector(selectEmployeesState, (state) => state?.employee);
export const selectEmployeesEntitiesState = createSelector(selectEmployeesState, (state) => state?.employees);

export const {
  selectIds: selectemployeesIds,
  selectEntities: selectemployeesEntities,
  selectAll: selectAllemployees,
  selectTotal: selectTotalemployees,
} = adapter.getSelectors(selectEmployeesEntitiesState);

const selectRoutedEmployee = createSelector(selectemployeesEntities, selectRoutedEmployeeId, (entities, selectedId) => {
  return entities[selectedId!] as EmployeeModel;
});

const selectUserEmployee = createSelector(selectemployeesEntities, UserSelectors.selectUser, (entities, user) => {
  return entities[user?.EmployeeId!] as EmployeeModel;
});

const selectSelectedEmployee = createSelector(
  selectRoutedEmployee,
  selectUserEmployee,
  (employeeFromUrl, employeeFromUser) => {
    return employeeFromUrl || employeeFromUser;
  }
);

const selectedEmployeeIsUser = createSelector(selectSelectedEmployee, UserSelectors.selectUser, (employee, user) => {
  return employee?.EmployeeId === user?.EmployeeId;
});

const selectEmployeeDepartments = createSelector(
  selectSelectedEmployee,
  departmentSelectors.selectDepartmentsForDropDown,
  (employee, deparments) =>
    deparments.filter((d) => employee?.ContactInformation.DepartmentIds?.includes(d.DepartmentId))
);

const selectHasEmpployeeFormChanges = createSelector(
  selectEmployeeState,
  (state) => state?.employeePage.hasFormChanges
);

const selectHasLoadError = createSelector(selectEmployeeState, (state) => state?.employeePage.error);

export const selectEmployeeById = (employeeId: number) =>
  createSelector(selectAllemployees, (employees) => {
    return employees?.find((f) => f.EmployeeId === employeeId);
  });

export const selectEmployeeFullNameById = (employeeId: number) =>
  createSelector(selectAllemployees, (employees) => {
    var employee = employees?.find((f) => f.EmployeeId === employeeId);
    if (employee) {
      return {
        name: `${employee.ContactInformation?.FirstName} ${employee.ContactInformation?.LastName}`,
        archived: employee.IsArchived,
        terminationInProcess: employee.IsEmploymentTerminationStarted,
      };
    }
    return { name: '' };
  });

export const selectEmployeeFirstNameById = (employeeId: number) =>
  createSelector(selectAllemployees, (employees) => {
    var employee = employees?.find((f) => f.EmployeeId === employeeId);
    if (employee) {
      return {
        name: `${employee.ContactInformation?.FirstName}`,
        archived: employee.IsArchived,
        terminationInProcess: employee.IsEmploymentTerminationStarted,
      };
    }
    return { name: '' };
  });

export const selectedEmployeeIsSelf = createSelector(
  selectUserEmployee,
  selectSelectedEmployee,
  (user, selectedEmployee) => {
    return user?.EmployeeId == selectedEmployee?.EmployeeId;
  }
);

export const selectEmployeesIsLoaded = createSelector(selectEmployeesEntitiesState, (state) => state.loaded);

export const selectArchivedEmployeesIsLoaded = createSelector(
  selectEmployeesEntitiesState,
  (state) => state.archivedLoaded
);

export const selectEmployesAndArchivedEmployeesIsLoaded = createSelector(
  selectEmployeesEntitiesState,
  selectEmployeesIsLoaded,
  selectArchivedEmployeesIsLoaded,
  (state, employeesLoaded, archivedLoaded) => {
    if (!state.showArchived) {
      return employeesLoaded;
    }
    return employeesLoaded && archivedLoaded;
  }
);

export const selectEmployeeAndManageableEmployeesIsLoaded = createSelector(
  selectEmployesAndArchivedEmployeesIsLoaded,
  selectManageableEmployeesIsLoaded,
  (employeeLoaded, manageableEmployeesLoaded) => employeeLoaded && manageableEmployeesLoaded
);

export const selectEmployeeExistsAndCanManage = (employeeId: number) =>
  createSelector(selectAllemployees, selectManageableEmployeeIds, (allEmployees, manageableEmployees) => {
    return {
      canManage: manageableEmployees?.some((id) => id === employeeId),
      exists: allEmployees.some((e) => e.EmployeeId === employeeId),
    };
  });

export const EmployeeSelectors = {
  selectRoutedEmployee,
  selectUserEmployee,
  selectSelectedEmployee,
  selectHasEmpployeeFormChanges,
  selectEmployeeDepartments,
  selectedEmployeeIsUser,
  selectHasLoadError,
};

export const selectMyEmployees = createSelector(
  selectAllemployees,
  selectManageableEmployeeIds,
  (employees, manageableIds) => {
    return employees.filter((employee) => manageableIds.includes(employee.EmployeeId) && !employee.IsArchived);
  }
);

export const selectMyEmployeesForDropdown = createSelector(selectMyEmployees, (employees) =>
  employees.map(
    (e) =>
      ({
        name: `${e.ContactInformation?.FirstName} ${e.ContactInformation?.LastName}`,
        id: e.EmployeeId,
      } as EmployeeDropdownModel)
  ).sort((a, b) => a.name.localeCompare(b.name))
);

export const selectSystemHasOtherAdmins = createSelector(
  selectSelectedEmployee,
  selectAllemployees,
  (selectedEmployee, employees) => {
    let systemHasOtherAdmins = false;
    employees.filter((e) => {
      if (e.EmployeeId != selectedEmployee.EmployeeId) {
        let system = e.Systems.find((s) => s.System == SystemEnum.MentorPersonal);
        let adminRole = system?.Roles?.filter((r) => r.RoleId == UserRole.AdministratorMentorPersonal);
        systemHasOtherAdmins = (adminRole && adminRole.length > 0) || systemHasOtherAdmins;
      }
    });

    return systemHasOtherAdmins;
  }
);

export const selectCanEditSystemAccess = createSelector(
  selectSelectedEmployee,
  selectedEmployeeIsSelf,
  selectHasPermission(AppPermissionEnum.IsMentorPersonalAdmin),
  selectSystemHasOtherAdmins,
  (selectedEmployee, selectedEmployeeIsSelf, userHasPermission, systemHasOtherAdmins) => {
    if (selectedEmployee) {
      if (selectedEmployeeIsSelf && userHasPermission) {
        return systemHasOtherAdmins;
      } else {
        return userHasPermission;
      }
    }
    return false;
  }
);
