import { EmployeeModel } from '@mentor-one-ui/core/models/employee/EmployeeModel';
import { EmployeeListFilter } from '@mentor-one-ui/employees/state/reducers/employees.reducer';
import { createAction, props } from '@ngrx/store';

export const EmployeeListPageActions = {
  Enter: createAction('[Employee List Page] Enter'),

  // filter actions
  SetFilterDepartments: createAction('[Employee list Page] Set filter: departments', props<{ departments: any }>()),
  SetFilterIsActive: createAction('[Employee list Page] Set filter: isActive', props<{ isActive: boolean }>()),
  SetFilterSearch: createAction('[Employee list Page] Set filter: search', props<{ search: string }>()),

  SetSelectedColumns: createAction('[Employee List Page] Set Selected Columns', props<{ columns: string[] }>()),
  ShowArchivedEmployees: createAction('[Employee List Page] Show Archived Employees'),
  EnableArchivedEmployees: createAction('[Employee List Page] Enable Archived Employees'),

  // singe employee actions
  ArchiveEmployee: createAction('[Employee List Page] Archive Employee', props<{ employee: EmployeeModel }>()),
  ConfirmArchiveEmployee: createAction('[Employee List Page] Confirm Archive Employee'),
  NavigateToEmployment: createAction('[Employee List Page] Navigate To Employment'),
  RejectArchiveEmployee: createAction('[Employee List Page] Reject Archive Employee'),
  UnarchiveEmployee: createAction('[Employee List Page] Unarchive Employee', props<{ employee: EmployeeModel }>()),
  ActivateToggleEmployee: createAction('[Employee List Page] Activate Employee', props<{ employee: EmployeeModel }>()),
  DeleteEmployee: createAction('[Employee List Page] Delete Employee', props<{ employee: EmployeeModel }>()),
  OpenMobileFilter: createAction('[Employee List Page] Open Mobile Filter'),
  CloseMobileFilter: createAction('[Employee List Page] Close Mobile Filter'),

  // multiple employee actions
  ArchiveEmployeeList: createAction(
    '[Employee List Page] Archive Employee List',
    props<{ employees: EmployeeModel[] }>()
  ),
  ActivateToggleEmployeeList: createAction(
    '[Employee List Page] Activate Employee List',
    props<{ employees: EmployeeModel[]; isActive: boolean }>()
  ),
};
