import { Action, combineReducers } from '@ngrx/store';
import * as fromReports from '../reports/state';
import * as fromStatistics from '../statistics/state';
import * as fromFollowUp from '../follow-up/state';

export const sickLeaveFeatureKey = 'sickLeave';

export interface SickLeaveState {
  [fromReports.featureKey]: fromReports.State;
  [fromStatistics.featureKey]: fromStatistics.State;
  [fromFollowUp.featureKey]: fromFollowUp.State;
}

export function reducers(state: SickLeaveState | undefined, action: Action) {
  return combineReducers({
    [fromReports.featureKey]: fromReports.reducer,
    [fromStatistics.featureKey]: fromStatistics.reducer,
    [fromFollowUp.featureKey]: fromFollowUp.reducer,
  })(state, action);
}
