import { createAction, props } from "@ngrx/store";
import { SickLeaveStatisticsFilterModel, SickLeaveStatisticsModel } from "../models/statistics.model";


export const SickLeaveStatisticsPageActions = {
  Enter: createAction('[SickLeave Statistics Page] Enter Page'),
  SetFilter: createAction('[SickLeave Statistics Page] Set Filter', props<{ filter: SickLeaveStatisticsFilterModel }>()),
  ClearData: createAction('[SickLeave Statistics Page] Clear Data'),
}

export const SickLeaveStatisticsApiActions = {
  LoadStatisticsSuccess: createAction('[SickLeave Statistics API] Load Statistics Success', props<{ response: SickLeaveStatisticsModel }>()),
  LoadStatisticsError: createAction('[SickLeave Statistics API] Load Statistics Failure', props<{ code: string, message: any }>()),
}
