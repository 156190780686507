<div class="main-header">
  <div #mainheader class="main-header__globalMenu">
    <h1 class="main-header__pagetitle">
      <a routerLink="/">
        <div *ngIf="santaSeason" id="santa-illustration">
          <img src="/assets/images/vector-illustrations/santas-hat.svg" alt="">
        </div>
        <img class="desktop-logo" src="/assets/images/main-logo.svg" [alt]="title">
        <img class="phone-logo" src="/assets/images/phone-logo.svg" [alt]="title">     
      </a>
    </h1>
    <div *ngIf="currentOrganizationId && organizations.length == 1 && !xsmallScreen()" class="organization-name">{{currentOrganizationName}}</div>

    <button *ngIf="useComplexMenu() && currentOrganizationId && organizations.length > 1" iconPos="right" #btn type="button" class="p-button" pButton icon="fal fa-exchange"
      [label]="xsmallScreen() ?  '' : currentOrganizationName" (click)="showMenuModal()"></button>

    <button *ngIf="!useComplexMenu() && currentOrganizationId && organizations.length > 1" #btn type="button" iconPos="right" class="p-button" pButton icon="fal fa-exchange"
      [label]="xsmallScreen() ?  '' : currentOrganizationName" (click)="organizationsMenu.toggle($event)"></button>
    <p-menu #organizationsMenu styleClass="header-menu" [style]="{'width': '400px'}"
      [model]="availableOrganizationsMenu" (onShow)="createAvailableOrganizationsMenu()" [popup]="true" appendTo="body">
      <ng-template pTemplate="item" let-item>
        <a class="p-menuitem-content p-menuitem-link">
          <span [class]="item.icon"></span>
          <span style="margin-left: 4px"> {{ item.label }}</span>
          <i class="fas fa-user-headset" style="margin-left: 8px" *ngIf="item.isSupportLogin"></i>
          <span *ngIf="item.isSupportLogin" style="margin-left: 4px">{{item.expires}}</span>
        </a>
      </ng-template>
    </p-menu>

  </div>
  <div class="main-header__userMenu">
    <div>
      <button type="button" pButton pRipple *ngIf="(currentUserName$ | async) as currentUserName" icon="fal fa-user" class="user-menu-icon"
        [label]="showMenu() ?  '' : currentUserName" (click)="userMenu.toggle($event)"></button>
      <p-menu #userMenu [popup]="true" styleClass="header-menu" [model]="userMenuItems" appendTo="body"></p-menu>
    </div>
    <div *ngIf="showMenu()">
      <button pButton (click)="visibleMainNavigation = true" icon="fal fa-bars" class="p-button-rounded"
        [title]="'menu' | translate | async"></button>

      <p-sidebar #tools [(visible)]="visibleMainNavigation" position="right">
        <app-main-navigation (closeMenu)="closeMenu()"></app-main-navigation>
      </p-sidebar>
    </div>
  </div>
</div>